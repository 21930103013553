import axios from "axios";
import { toast } from "sonner";

import {
  GetConfigRes,
  GetMonthsRes,
  LoginBody,
  LoginRes,
  UserInfo,
} from "@/api/api.types";

const authApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
});

export const tokenIsSet = () =>
  typeof localStorage != "undefined" && localStorage.getItem("token") !== null;

tokenIsSet() &&
  (authApi.defaults.headers.common["Authorization"] =
    `Bearer ${localStorage.getItem("token")}`);

authApi.defaults.headers.common["Content-Type"] =
  "application/json;charset=UTF-8";

authApi.interceptors.response.use(
  (res) => res,
  (error) => {
    console.log(error.response);
    if (error.response?.data && error.response?.data?.message) {
      if (typeof error.response?.data?.message === "object") {
        for (const key in error.response?.data?.message) {
          toast.error(error.response?.data?.message[key], {
            position: "top-center",
            dismissible: true,
          });
        }
      } else {
        toast.error(error.response?.data?.message, {
          position: "top-center",
          dismissible: true,
        });
      }
    }

    // if (error.response?.status === 403) {
    //     localStorage.removeItem('token')
    //     authApi.defaults.headers.common['Authorization'] = ''
    // }
    return Promise.reject(error);
  },
);

export const apiLogout = () => {
  localStorage.removeItem("token");
  authApi.defaults.headers.common["Authorization"] = "";

  toast.success("Logged Out!", {
    position: "top-center",
    dismissible: true,
  });
};

export const apiLogin = async (body: LoginBody) => {
  const login = (await authApi.post<LoginRes>(`/user/login`, body)).data;

  apiSetToken(login.token, true);
};

const apiSetToken = (token: string, withInfo = false) => {
  localStorage.setItem("token", token);
  authApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  if (withInfo) {
    toast.success("Logged in successfully", {
      position: "top-center",
      dismissible: true,
    });
  }
};

export const apiRefreshToken = async () => {
  const login = (await authApi.get<LoginRes>(`/user/refresh`)).data;

  apiSetToken(login.token);
};

export const fetchUser = async () =>
  (await authApi.get<UserInfo>("/user/profile")).data;

export const fetchConfig = async () =>
  (await authApi.get<GetConfigRes>("/stats/config")).data;

export const fetchMonths = async (year: number, month: number) =>
  (
    await authApi.get<GetMonthsRes[]>("/stats/statsPerMonth", {
      params: { year, month },
    })
  ).data;
