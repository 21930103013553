import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";

import { UserInfo } from "@/api/api.types";
import { apiLogout, apiRefreshToken, fetchUser, tokenIsSet } from "@/api/api";

interface TUserContext {
  loggedIn: boolean;
  user?: UserInfo;
  refresh: () => void;
  logOut: () => void;
  logIn: () => void;
}

const initialUserContext: TUserContext = {} as TUserContext;
const UserContext = createContext<TUserContext>(initialUserContext);

export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const { push } = useRouter();

  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [tokenSet, setTokenSet] = useState<boolean>(tokenIsSet());

  const { data, isSuccess, refetch } = useQuery({
    queryKey: ["user"],
    queryFn: () => fetchUser(),
    enabled: tokenSet,
  });

  const logIn = () => {
    setTokenSet(true);
    refetch();
    push(`/stats`);
  };

  const logOut = () => {
    apiLogout();
    setTokenSet(false);
    setLoggedIn(false);
    push("/");
  };

  useEffect(() => {
    if (isSuccess && data) {
      setLoggedIn(true);
      apiRefreshToken();
    }
  }, [isSuccess]);

  return (
    <UserContext.Provider
      value={{
        refresh: refetch,
        loggedIn,
        user: data,
        logOut,
        logIn,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};
