import type { AppProps } from "next/app";

import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { useRouter } from "next/router";
import "@/styles/globals.css";
import { Toaster } from "sonner";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import ProviderWrapper from "@/provider/ProviderWrapper";
import { fontSans, fontMono } from "@/config/fonts";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24,
    },
  },
});

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  return (
    <NextUIProvider navigate={router.push}>
      <NextThemesProvider attribute="class">
        <Toaster />
        <QueryClientProvider client={queryClient}>
          <ProviderWrapper>
            <Component {...pageProps} />
          </ProviderWrapper>
          {process.env.NEXT_PUBLIC_ENV_DEV_MODE && (
            <ReactQueryDevtools initialIsOpen buttonPosition="bottom-left" />
          )}
        </QueryClientProvider>
      </NextThemesProvider>
    </NextUIProvider>
  );
}

export const fonts = {
  sans: fontSans.style.fontFamily,
  mono: fontMono.style.fontFamily,
};
